<template>
  <div class="materials">
    <div class="material">
      <div class="cover" :style="'stuc_chaux_strie_500.jpg' | cover | cssImage"></div>
      <h2>Chaux</h2>
      <p>Connue depuis la plus haute Antiquité, la chaux est issue de la cuisson du calcaire. Qu’elle soit hydraulique ou aérienne elle possède des vertus assainissantes et laisse respirer les murs. Elle peut être utilisée en intérieur comme en extérieur avec une grande variété de finitions.</p>
    </div>
    <div class="material">
      <div class="cover" :style="'platre_gratte_500.jpg' | cover | cssImage"></div>
      <h2>Plâtre</h2>
      <p>Obtenu par chauffage modéré du gypse, le plâtre est probablement employé depuis la préhistoire. Sensible à l’eau, il ne peut être utilisé à l’extérieur. Poreux, il régule l’hygrométrie des pièces, il est aussi un bon isolant. L’adjonction de charges et de pigments le transforme en enduit décoratif.</p>
    </div>
    <div class="material">
      <div class="cover" :style="'argile_500.jpg' | cover | cssImage"></div>
      <h2>Argile</h2>
      <p>L’argile est le plus vieil enduit mural, utilisé sur tous les continents. Mélangée à de la paille, compressée, elle constitue la structure même du bâti. Tamisée, pigmentée, associée à de la poudre de marbre elle se métamorphose en enduit de finition au velouté profond et enveloppant. L’argile est un régulateur d’hygrométrie, absorbe les odeurs et améliore l’ambiance phonique.</p>
    </div>
    <div class="material">
      <div class="cover" :style="'betongrisocre_500.jpg' | cover | cssImage"></div>
      <h2>Ciment</h2>
      <p>Le ciment Portland fabriqué à partir du XIXème siècle, est un composé de chaux, silice et oxyde de fer, chauffé à très haute température. Utilisé pour le béton ciré, il est mélangé à de la poudre de marbre produisant une pâte onctueuse et soyeuse. Très poreux, il est ensuite traité en finition avec un bouche-pore puis un vernis ou de la cire de carnauba pour le rendre étanche.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Materials',
  filters: {
    cover (filename) {
      return `https://www.ldekor.fr/assets/images/matieres-resized/${filename}`
    },
  },
}
</script>

<style lang="stylus" scoped>
.materials
  display flex
  justify-content center

  +small()
    flex-direction column

  .material
    width 225px
    margin 0 1% 15vh 1%
    background-color $color-light-grey
    padding 1.5%

    @media screen and (min-width: 1600px)
      padding 30px

    +small()
      width auto
      margin 0 30px 30px 30px
      box-sizing border-box

    .cover
      @extend $common-center-cover
      width 100%
      height 200px

      +small()
        height 50px

    h2
      font-size 20px

      +small()
        font-size 35em

    p
      font-size 16px

      +small()
        font-size 35em
</style>
