<template>
  <div class="partners">
    <h2 class="partners__title">Mes partenaires</h2>
    <ul class="partners__links">
      <li v-for="(link, index) in links" :key="index">
        <a :href="link.url" target="_blank" rel="noreferrer noopener">
          <img :src="link.pic" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Partners',
  props: {
    links: { type: Array, default: () => [] },
  },
}
</script>

<style lang="stylus" scoped>
.partners
  background-color $color-light-grey
  padding 30px 0

  &__title
    font-size 26px
    text-align center
    color $color-light-text

    +small()
      font-size 35em * 1.2em

  &__links
    text-align center
    padding 0
    margin 0

    li
      display inline-block
      width 13%
      height @width

      +small()
        width 15%
        height @width

      img
        width 100%
</style>
