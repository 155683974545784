<template>
  <nav class="main-nav">
    <router-link class="button-home" to="/">L&nbsp;Dékor</router-link>
    <router-link class="button-contact" to="/contact">Contact</router-link>
  </nav>
</template>

<script>
export default { name: 'MainNav' }
</script>

<style lang="stylus" scoped>
.on-top .main-nav
  background-color alpha(white, 0)

  .button-home
    opacity 0
    color white

  .button-contact
    color white

.main-nav, .on-top.on-modal .main-nav
  position fixed
  top 0
  left 0
  right 0
  font-size 16px
  width 100vw
  height 70px
  background-color white
  transition background-color 0.5s ease-in-cubic
  overflow hidden
  z-index 1

  .button-home, .button-contact
    position absolute
    color $color-text
    text-decoration none
    cursor pointer
    margin 0
    padding 0
    line-height normal
    transition color 0.4s ease-in-out-cubic, opacity 0.5s 0.5s

    &:hover
      color $color-high

  .button-home
    top 10px
    left 40px
    opacity 1
    font-size 30px
    font-family 'Dry_Brush'
    font-weight 100

  .button-contact
    position absolute
    top 18px
    right 40px
    font-size 22px
    font-weight $medium-weight
    text-transform lowercase
</style>
