import AppData from '../models/AppData'

export const APP_DATA = new AppData()

export async function loadAppData() {
  const rawData = await fetch('/data/data.json')
  try {
    APP_DATA.config = await rawData.json()
  } catch(error) {
    console.log('Error loading data ...') /* eslint-disable-line */
    console.error(error) /* eslint-disable-line */
  }
}