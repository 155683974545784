import { META_TITLE, META_DESCRIPTION } from '../constants/AppConstants'
import MosaicItem from '../models/MosaicItem'
import More from '../components/More'
import Picture from '../models/Picture'


export const parseItemData = (data, pathAsset, itemDirectory) => {
  const buildPicPath = picName => {
    const fileExtension = picName.substr(-3)
    const fileName = picName.slice(0, -4)
    const pic = new Picture()
    pic.small = `${pathAsset}/${fileName}_400.${fileExtension}`
    pic.large = `${pathAsset}/${fileName}_1024.${fileExtension}`
    pic.thumb = `${pathAsset}/${fileName}_300x300.${fileExtension}`
    return pic
  }
  const model = new MosaicItem()
  model.title = data.title || model.title
  model.baseline = data.baseline || model.baseline
  model.description = data.description || model.description
  model.keywords = data.keywords || model.keywords
  model.verb = data.verb || model.verb
  model.url = data.url && data.url.length ? data.url : model.url
  model.link = model.url ? `/${itemDirectory}/${model.url}` : model.link
  model.pics = Array.from(data.pics, buildPicPath) || model.pics
  model.seoTitle = data.seoTitle || META_TITLE
  model.seoDescription = data.seoDescription || META_DESCRIPTION
  return model
}

export const parseItemCollectionData = data => Array.from(data.items, itemData => parseItemData(itemData, data.pathAssets, data.itemDirectory))

export const buildItemRoute = item => ({
  path: item.link,
  component: More,
  props: { item },
  meta: {
    title: item.seoTitle,
    description: item.seoDescription,
  }
})

export const workFilter = item => item.url