class MosaicItem {
  constructor () {
    this.title = ''
    this.baseline = ''
    this.description = ''
    this.keywords = ''
    this.verb = ''
    this.url = null
    this.link = null
    this.pics = []
    this.seoTitle = ''
    this.seoDescription = ''
  }
}
export default MosaicItem