<template>
  <transition name="popin">
    <div class="modal">
      <div class="modal-content">
        <slot />
        <div class="button-back" @click="close">&larr;</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  mounted () { this.$emit('open') },
  destroyed () { this.$emit('close') },
  methods: {
    close () { this.$emit('back') }
  }
}
</script>

<style lang="stylus" scoped>
.modal
  @extend $common-absolute-full
  height auto
  position fixed
  top 69px
  bottom 0
  z-index 2

  .modal-overlay
    @extend $common-absolute-full
    background-color alpha(white, 0.7)
    cursor pointer
    display none

  .modal-content
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    background-color #ffffff
    overflow hidden

  .button-back
    position absolute
    top 26px
    right 0
    background-color $color-text
    color white
    line-height 1
    padding 5px 40px 5px 20px
    z-index 1
    transition background-color 0.3s, padding-right 0.3s
    font-size 25px
    text-transform lowercase
    cursor pointer

    &:hover
      background-color $color-high
      padding-right 45px

  .button-close
    position absolute
    top 30px
    right @top
    fill $color-dark
    width 30px
    height @width
    cursor pointer
    z-index 1
    transition fill 0.3s

    &:hover
      fill $color-high

/* child transition works when parent have transition ??!! */
.popin-enter
  opacity 0.9

  .modal-overlay
    opacity 0

  .modal-content
    width 0

.popin-enter-to
  opacity 1
  transition opacity 1s // add bullshit transition for enable child transition

  .modal-overlay
    opacity 1
    transition opacity 1s

  .modal-content
    width 100vw
    transition width 0.3s ease-in-cubic

.popin-leave-active
  transform translate3d(-100vw, 0, 0)
  transition transform 0.2s ease-in-cubic
</style>
