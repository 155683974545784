<template>
<root
  id="app"
  :class="{
    'on-top': onTop,
    'on-mosaic': onMosaic,
  }"
>
  <slideshow
    slot="content"
    :auto="onTop"
    :pager="onTop"
  >
    <hello :push-news="pushNews"/>
    <div class="content">
      <whoami/>
      <mosaic
        :items="items"
        ref="mosaic"
      />
      <materials/>
      <partners :links="partners"/>
      <footer
        is="Footer"
        :items="items"
      />
    </div>
  </slideshow>
  <router-view slot="modal"/>
  <main-nav slot="nav"/>
</root>
</template>

<script>
import Footer from './components/Footer.vue'
import Hello from './components/Hello.vue'
import MainNav from './components/MainNav.vue'
import Materials from './components/Materials.vue'
import More from './components/More.vue'
import Mosaic from './components/Mosaic.vue'
import Partners from './components/Partners.vue'
import Root from './components/Root'
import Slideshow from './components/Slideshow.vue'
import Whoami from './components/Whoami.vue'

import throttle from 'lodash.throttle'
import { parseItemCollectionData } from './helpers/MosaicItemHelper'
import { APP_DATA } from './helpers/AppDataHelper'

export default {
  name: 'App',
  components: {
    Footer,
    Hello,
    MainNav,
    Materials,
    More,
    Mosaic,
    Partners,
    Root,
    Slideshow,
    Whoami
  },
  data () {
    return {
      pushNews: null,
      items: [],
      partners: [],
      scrollValue: 0
    }
  },
  computed: {
    onTop () { return this.scrollValue === 0 },
    onMosaic () {
      const mosaic = this.$refs.mosaic ? this.$refs.mosaic.$el : null
      const offset = mosaic ? mosaic.offsetTop : window.innerHeight * 2
      return offset - this.scrollValue <= (window.innerHeight * .75)
    },
  },
  mounted () {
    this.items = parseItemCollectionData(APP_DATA.config)
    this.partners = APP_DATA.config.partners
    this.pushNews = APP_DATA.config.push
    document.addEventListener('scroll', this.scrollHandler)
    /* ready event for prerendering */
    document.dispatchEvent(new Event(process.env.VUE_APP_EVENT_READY))
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    scrollHandler: throttle(function () {
      this.scrollValue = document.body.scrollTop || document.documentElement.scrollTop
    }, 100)
  },
}
</script>

<style lang="stylus">
@import '~normalize-styl/normalize.styl'

@font-face
  font-family 'Dry_Brush'
  src url('./assets/fonts/dry_brush-webfont.woff2') format('woff2'),
  url('./assets/fonts/dry_brush-webfont.woff') format('woff')
  font-weight normal
  font-style normal

html, body
  background-color white
  font-family 'Cabin', sans-serif
  color $color-text
  font-weight normal
  width 100%
  height auto
  overflow-x hidden

html
  overflow-y scroll

#app
  width 100vw
  height auto

.debug
  position fixed
  top 10px
  left 10px
  padding 5px
  background-color alpha(black, 0.5)
  font-size 16px
  color white
  line-height normal
  z-index 10
  p
    margin 0
    padding 0

.content
  position relative
  z-index 1
  padding-top 100vh
  box-sizing border-box
  width 100vw
  overflow hidden

</style>
