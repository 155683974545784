<template lang="html">
  <transition name="more-transition">
    <div class="more">
      <div class="more-cover">
        <div
          class="more-cover__blur"
          :style="cover | cssImage">
        </div>
        <img
          class="more-cover__pic"
          :src="cover"
          :alt="coverAlt"
        />
      </div>
      <div class="more-text">
        <h2 class="more-text__title">{{ item.title }}</h2>
        <h3 class="more-text__baseline">{{ item.baseline }}</h3>
        <div class="more-text__description">
          <p v-if="item.description">{{ item.description }}</p>
          <p v-else>Pas de description disponible ...</p>
        </div>
        <div class="more-text__pics">
          <div
            class="more-text__pics-thumb"
            v-for="n in item.pics.length"
            :key="n"
            :class="{ 'active': ((n-1)==current) }"
            @click="current = (n-1)"
          >
            <img
              class="more-text__pics-pic"
              :src="item.pics[n-1].thumb"
              :alt="coverAlt"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MosaicItem from '../models/MosaicItem'
import { META_TITLE_BASE } from '../constants/AppConstants'

export default {
  name: 'More',
  props: {
    item: { type: MosaicItem, default: () => new MosaicItem() }
  },
  data () {
    return {
      current: 0,
    }
  },
  computed: {
    cover () { return this.item.pics[this.current].large },
    coverAlt () { return `${META_TITLE_BASE}, ${this.item ? this.item.keywords : ''}` },
  },
}
</script>

<style lang="stylus" scoped>
.more
  @extend $common-block-full
  background-color $color-light-grey

  &-cover
    @extend $common-center-cover
    position relative
    width 50%
    display flex
    vertical-align top
    float left
    transform translateX(0)
    transition transform 0.3s ease-in-out-cubic 0.3s
    background-image none !important
    background-color white

    +small()
      display none

    &__blur
      @extend $common-absolute-full
      @extend $common-center-cover
      position absolute
      filter blur(10px)
      opacity 0.5
      z-index 2

    &__pic
      position relative
      z-index 3
      max-width 100%
      max-height 100%
      display block
      margin auto

  &-text
    display flex
    flex-direction column
    width 50%
    height 100%
    padding 30px
    position relative
    box-sizing border-box
    transition opacity 0.3s ease-in-cubic 0.5s
    z-index 1
    background-color $color-light-grey

    +small()
      width 100%

    &__title
      font-size 26px
      margin 0
      padding 0
      display inline-block
      margin-bottom 30px
      padding-right 60px
      font-weight $bold-weight

    &__baseline
      font-size 20px
      margin 0
      padding 0
      font-weight $bold-weight
      color $color-light-text
      margin-bottom 30px

    &__description
      padding-right 30px
      overflow-y auto
      -webkit-overflow-scrolling touch
      font-size 16px
      line-height 25px
      font-weight $medium-weight
      flex 1

      *
        margin 0
        margin-bottom 10px

    &__pics
      width 100%

      &-thumb
        position relative
        display inline-block
        width 70px
        height @width
        margin-right 15px
        margin-top 15px
        cursor pointer
        overflow hidden

        &:after
          content ''
          @extend $common-absolute-full
          box-sizing border-box
          border 7px solid alpha(white, 0.5)
          transform scale3d(1.3, 1.3, 1)
          transition transform 0.3s ease-out-cubic

        &:hover
          &:after
            transform scale3d(1.1, 1.1, 1)

        &.active
          &:after
            border 7px solid alpha(white, 0.5)
            transform scale3d(1, 1, 1)

        +small()
          cursor auto

          &:after
            display none

      &-pic
        @extend $common-absolute-full

.more-transition-enter
  .more-cover
    transform translateX(-100%)

  .more-text
    opacity 0
</style>
