<template>
  <footer class="footer">
    <h1 class="footer__title">Françoise Lacombe-Bonnet</h1>
    <h2 class="footer__logo">L&nbsp;Dékor</h2>
    <div class="footer__columns">
      <ul class="footer__list">
        <li>Décoration d'Intérieur</li>
        <li>Conseil couleur et harmonisation</li>
        <li>Enduit mur</li>
        <li>Finitions décoratives</li>
        <li>Matériaux écologiques</li>
        <li>Chaux, Argile, Plâtre, Béton ciré, Peinture, Patines</li>
      </ul>
      <infos class="footer__infos" />
    </div>
    <ul class="footer__links" v-if="items.length">
      <li v-for="(item, index) in items.filter(item => item.link !== null)" :key="`link#${index}`">
        <router-link :to="item.link">{{ item.title }}</router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
import Infos from './Infos'

export default {
  name: 'Footer',
  components: { Infos },
  props: {
    items: { type: Array, default: () => [] },
  },
}
</script>

<style lang="stylus" scoped>
.footer
  width 800px
  margin 0 auto
  padding 40px
  font-size 16px
  color $color-light-text

  +small()
    width 100%

  *
    margin 0
    padding 0

  &__title
    font-size 20px
    display inline-block
    font-weight $bold-weight
    color $color-text

  &__logo
    font-size 20px
    font-family 'Dry_Brush'
    line-height 1.2
    font-weight 100
    color $color-light-text
    margin 30px 0

  &__columns
    >ul
      width 50%

  &__list
    list-style none

    li
      margin 3px 0

  &__links
    list-style none
    text-align center
    margin-top 40px
    text-transform lowercase
    font-size small

    li
      display inline-block

    li:not(:first-child)
      &:before
        content '\2022'
        padding 0 6px

    +small()
      display none

  &__columns
    display flex
</style>

<style lang="stylus">
.footer
  a, a:hover, a:visited, a:active
    text-decoration none
    color $color-light-text

  a:hover
    color $color-high

  &__infos
    li
      margin 3px 0

      +small()
        display none
</style>
