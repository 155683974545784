<template>
  <ul class="infos">
    <li
      class="infos__map"
    >Françoise Lacombe-Bonnet<br>Atelier LDékor<br>81170 FRAUSSEILLES</li>
    <li class="infos__facebook">
      <a href="https://www.facebook.com/ldekor81/" target="_blank" rel="noreferrer noopener">facebook</a>
    </li>
    <li class="infos__tel">
      <a :href="'tel:' + getPhoneDigits.join('')" target="_blank" rel="noreferrer noopener">{{ getPhoneDigits.join(' ') }}</a>
    </li>
    <li class="infos__mail">
      <a :href="'mailTo:' + getMail" target="_blank" rel="noreferrer noopener">{{ getMail }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    getMail () {
      const name = 'ldekor'
      const domain = 'orange.fr'
      return `${name}@${domain}`
    },
    getPhoneDigits () {
      return ['06', '88', '14', '83', '30']
    }
  }
}
</script>

<style lang="stylus" scoped>
.infos
  list-style none
  margin 0
  padding 0

  &__map, &__facebook, &__tel, &__mail
    display flex
    justify-content flex-start
    align-items flex-start

    &:before
      content ''
      display inline-block
      width 15px
      height @width
      margin-top 2px
      margin-right 5px
      background-repeat no-repeat

  &__map:before
    background-image url('~@/assets/pictos/placeholder.svg')

  &__facebook:before
    background-image url('~@/assets/pictos/facebook.svg')

  &__tel:before
    background-image url('~@/assets/pictos/phone-call.svg')

  &__mail:before
    background-image url('~@/assets/pictos/mail.svg')
</style>
