import Vue from 'vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import App from './App.vue'
import Contact from './components/Contact.vue'
import {
  META_TITLE_BASE,
  META_TITLE_DEFAULT,
  META_DESCRIPTION_HEADER,
  META_DESCRIPTION_BODY,
  META_DESCRIPTION_FOOTER
} from './constants/AppConstants'
import { loadAppData, APP_DATA } from './helpers/AppDataHelper'
import { parseItemCollectionData, buildItemRoute, workFilter } from './helpers/MosaicItemHelper'


Vue.config.productionTip = false

Vue.use(VueResource)
Vue.use(VueRouter)

Vue.filter('cssImage', function (url) {
  return { 'background-image': `url(${url})` }
})

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

const createRouter = () => new Promise(async resolve => {
  await loadAppData()
  const items = parseItemCollectionData(APP_DATA.config)
  const works = items.filter(workFilter)
  const routes = [{
    path: '*',
    meta: {
      title: `${META_TITLE_BASE}, ${META_TITLE_DEFAULT}`,
      description: `${META_DESCRIPTION_HEADER} ${META_DESCRIPTION_BODY} ${META_DESCRIPTION_FOOTER}`
    }
  }, {
    path: '/contact',
    component: Contact,
    meta: {
      title: `${META_TITLE_BASE}, vous avez un projet ?`,
      description: `${META_DESCRIPTION_HEADER} ${META_DESCRIPTION_BODY} ${META_DESCRIPTION_FOOTER}`
    }
  },
  ...(Array.from(works, buildItemRoute))
  ]

  const router = new VueRouter({
    mode: process.env.VUE_APP_SERVER !== 'development' ? 'history' : 'hash',
    base: '/',
    routes,
  })

  router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    document.getElementsByName('description')[0].content = to.meta.description
    next()
  })

  resolve(router)
})


createRouter().then(router => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
})
