<template>
  <div
    :class="{ 'on-modal': modalOpened }"
    :style="{ 'font-size': fontSize + 'px' }"
  >
    <slot name="content"/>
    <modal
      v-if="matchingRoute"
      @open="onModalOpen"
      @close="onModalClose"
      @back="onModalBack"
    >
      <slot name="modal"/>
    </modal>
    <slot name="nav"/>
  </div>
</template>

<script>
import Modal from './Modal'

export default {
  name: 'Root',
  components: { Modal },
  data () {
    return {
      fontSize: 1,
      modalOpened: false,
    }
  },
  computed: {
    matchingRoute() { return this.$route.matched[0].components.default }
  },
  mounted () {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    onModalOpen () {
      document.body.style.overflowY = 'hidden'
      document.documentElement.style.overflowY = 'hidden'
      this.modalOpened = true
    },
    onModalClose () {
      document.body.style.overflowY = 'auto'
      document.documentElement.style.overflowY = 'auto'
      this.modalOpened = false
    },
    onModalBack () { this.$router.back() },
    resizeHandler () { this.fontSize = Math.min(window.innerWidth / 960, 1) },
  }
}
</script>
