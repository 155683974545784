<template>
  <transition name="contact-transition">
    <div class="contact">
      <div
        class="contact__cover"
        :style="'https://www.ldekor.fr/assets/images/home/matieres_bleu.jpg' | cssImage"
      />
      <div class="contact__content">
        <h1 class="contact__title">Contact</h1>
        <div class="contact__description">
          <p>Vous avez un projet ? Une pièce, deux pièces... appartement ou maison, commerce ou bureau, ancien ou neuf... élaborons ensemble le décor qui vous inspire, qui vous ressemble.</p>
          <p>Implantée dans le Tarn, région de Gaillac, je suis en proximité de l’agglomération toulousaine. En fonction des chantiers, je me déplace de Bordeaux à Montpellier ! N’hésitez pas à me contacter pour tout renseignement complémentaire.</p>
        </div>
        <infos class="contact__infos" />
      </div>
    </div>
  </transition>
</template>

<script>
import Infos from './Infos.vue'

export default {
  name: 'Contact',
  components: { Infos },
}
</script>

<style lang="stylus" scoped>
.contact
  @extend $common-block-full
  background-color $color-light-grey

  &__cover
    @extend $common-center-cover
    width 30%
    display inline-block
    vertical-align top
    float left
    transform translateX(0)
    transition transform 0.3s ease-in-out-cubic 0.3s

    +small()
      display none

  &__content
    display flex
    flex-direction column
    width 50%
    height 100%
    padding 30px
    position relative
    box-sizing border-box
    transition opacity 0.3s ease-in-cubic 0.5s

    +small()
      width 100%

  &__title
    font-size 26px
    margin 0
    padding 0
    display inline-block
    margin-bottom 30px
    padding-right 60px
    font-weight $bold-weight


  &__description
    padding-right 30px
    overflow-y auto
    -webkit-overflow-scrolling touch
    font-size 16px
    line-height 25px
    font-weight $medium-weight
    flex 1

  &__infos
    font-size 16px
    margin-top 50px

.contact-transition-enter
  .contact__cover
    transform translateX(-100%)

  .contact__content
    opacity 0
</style>

<style lang="stylus">
.contact__content
  *
    margin 0
    margin-bottom 10px

.contact__infos
  a, a:hover, a:visited, a:active
    text-decoration none
    color $color-text

  a:hover
    color $color-high
</style>