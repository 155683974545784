<template>
  <div class="whoami">
    <div class="header">
      <div class="photo"/>
      <div class="me">
        <h2>Françoise Lacombe-Bonnet</h2>
        <h3>Artisan matiériste coloriste</h3>
      </div>
    </div>
    <p class="description"><span class="high-light exergue">Cœur de métier, métier de cœur</span></p>
    <p class="description">Attirée depuis toujours par la décoration et la couleur, j’ai professionnalisé ma passion en me formant à l’Ecole Européenne de l’Art et des Matières à Albi afin de pouvoir mettre mes compétences au service des particuliers et des professionnels.</p>
    <p class="description">Convaincue que l’organisation de l’espace de vie ou de travail, l’ambiance chromatique et l’harmonie visuelle influencent notre bien être, je suis également sensible à l’utilisation de matériaux respectueux de l’environnement, et qui par ailleurs ont traversé l’histoire du bâti pour perdurer jusqu’à nos jours.</p>
    <p class="description">Initiée à des « recettes » séculaires, je privilégie l’application de matières nobles, écologiques et intemporelles : chaux, argile et plâtre afin de créer des textures et des effets décoratifs.</p>
    <p class="description">Ces savoir-faire me permettent de travailler dans la rénovation et la réhabilitation de constructions anciennes, mais aussi, de réinterpréter et de valoriser les enduits dans des créations contemporaines.</p>
  </div>
</template>

<script>
export default {
  name: 'Whoami',
}
</script>

<style lang="stylus" scoped>
$padding-text = ((800px - 550px) / 2)
.on-top .whoami
  opacity 0
  transition opacity 0.3s 0s

.on-mosaic .whoami
  background-color $color-light-grey

.whoami
  width 800px
  margin 0 auto 10vh auto
  padding-bottom 90px
  background-color white
  transition opacity 1s, background-color 0.3s

  +small()
    width 100%
    padding-bottom 20%
    margin-bottom 0

  .header
    padding 60px $padding-text
    background-color $color-light-grey

    +small()
      padding 20% 10%
      text-align center

  .photo
    @extend $common-center-cover
    background-image url('https://www.ldekor.fr/assets/images/avatar.jpg')
    width 82px
    height @width
    border-radius (@width / 2)
    display inline-block
    vertical-align top

    +small()
      width 300em
      height @width
      border-radius (@width / 2)
      margin 0 auto
      display block

  .me
    position relative
    top -4px
    margin-left 50px
    display inline-block
    vertical-align top

    +small()
      margin-left 0
      top 0
      margin-top 100em

  h2, h3
    margin 0
    padding 0

  h2
    font-weight $regular-weight
    font-size 35px
    color $color-high
    line-height 1.5

    +small()
      font-size 65em

  h3
    font-weight $regular-weight
    font-size 29px
    letter-spacing 3px
    line-height 36px

    +small()
      font-size 47em
      line-height normal

  .description
    display inline-block
    font-weight $regular-weight
    font-size 20px
    line-height 28px
    margin 10px 0
    padding 0 $padding-text

    +small()
      padding 0 10%
      font-size 35em
      line-height 1.5

  .high-light
    font-weight $extra-bold-weight

  .exergue
    position relative
    font-size 26px
    margin 50px 0 40px 0
    display block

    &:before, &:after
      position relative
      font-family 'Times New Roman', Times, serif
      color $color-high
      font-weight $regular-weight
      font-size 80px
      line-height 0

    &:before
      position absolute
      content '“'
      top 10px
      left -40px

    &:after
      content '„'
      bottom -10px

    +small()
      font-size 1.2em

      &:before, &:after
        font-size 3em

      &:before
        left -0.5em
</style>
