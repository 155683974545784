<template lang="html">
  <div class="mosaic">
    <router-link
      :class="['mosaic-item', { 'mosaic-item--enabled': item.link }]"
      :to="item.link || '/'"
      v-for="(item, index) in items"
      :key="'item' + index"
    >
      <img
        class="mosaic-item__pic"
        :src="item.pics[0].thumb"
        :alt="`${altPrefix}, ${item.keywords}`"
      >
      <p class="mosaic-item__label">{{ item.title }}</p>
      <p class="mosaic-item__verb">{{ item.verb }}</p>
    </router-link>

    <div
      class="mosaic-item mosaic-item--empty"
      v-for="n in emptyItems"
      :key="'item' + items.length + n"
    />
  </div>
</template>

<script>
import { META_TITLE_BASE } from '../constants/AppConstants'

export default {
  name: 'Mosaic',
  data () {
    return { altPrefix: META_TITLE_BASE }
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  computed: {
    emptyItems () {
      return 4 - this.items.length % 4
    }
  }
}
</script>

<style lang="stylus" scoped>
.mosaic
  text-align center
  margin-bottom 10vh
  background-color $color-light-grey
  padding (35 / 1920 * 100)% 0

.mosaic-item
  position relative
  display inline-block
  width (320 / 1920 * 100)%
  padding-bottom @width
  height 0
  margin (35 / 1920 * 100)%
  overflow hidden
  cursor default
  border-radius 100%
  transition border-radius 0.3s ease-in-cubic

  +small()
    $marg = 2%
    width ((100 - 10 * $marg) / 4)%
    padding-bottom @width
    margin $marg

  &--empty
    background-color white
    visibility hidden

  &--enabled
    cursor pointer
    border-radius 3px

  &__pic
    @extend $common-absolute-full
    z-index 0

  &:after
    @extend $common-absolute-full
    content ''
    opacity 0
    transition opacity 0.4s ease-in-out-cubic
    background-color rgba(255 255 255 0.85)
    z-index 1

  &__label
    position absolute
    top 40%
    width 100%
    opacity 0
    font-size 18px
    font-weight $bold-weight
    color $color-text
    letter-spacing 3px
    margin -(@font-size * 0.5) 0 0 0
    padding 0 5%
    text-align center
    transition opacity 0.4s 0s
    z-index 2
    box-sizing border-box

    @media screen and (max-width: 1280px)
      top 35%
      font-size 16px

  &__verb
    position absolute
    bottom 5px
    width 100%
    opacity 0
    font-size 16px
    font-weight $regular-weight
    font-style italic
    color $color-light-text
    text-transform lowercase
    text-align center
    transition opacity 0.4s 0s
    z-index 3

  &:hover
    @media screen and (min-width: 959px)
      border-radius 3px
      transition border-radius 0.3s ease-out-cubic

      &:after
        opacity 1

      .mosaic-item
        &__label, &__verb
          opacity 1

        &__label
          transition opacity 0.4s 0.2s

        &__verb
          transition opacity 0.4s 0.4s
</style>
